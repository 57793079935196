import config from '@/config/ft-settings';
import urlConfig from '@/config/urlConfig';
import $store from '@/store';
import bus from '@/utils/bus';
import utils from '@/utils/tvPointcommon';
import $bridge from '@/utils/FTBridgeShared';
import $utils from '@/utils/tvPointcommon';
import FTInitSetUp from '@/modules/ft-init-setup';
import { Service } from '@/modules/ft-constants';

const commons = {
  printLog(str, obj) {
    if (config.isLog) {
      console.log(str, obj || '');
    }
  },
  isNullObject(param) {
    return Object.keys(param).length === 0 && param.constructor === Object;
  },
  isNull(object) {
    // jhkim: vue3:
    // return !!(object == null || object == '' || this.isUndefined(object));
    if (Array.isArray(object)) {
      return !!(object.length == 0);
    } else {
      return !!(object == null || object == '' || object == undefined);
    }
  },

  isUndefined(object) {
    return typeof object === 'undefined';
  },

  isEqual(obj1, obj2) {
    for (const p in obj1) {
      if (Object.hasOwnProperty.call(obj1, p)) {
        if (obj1[p] !== obj2[p]) {
          return false;
        }
      }
    }
    for (const p in obj2) {
      if (Object.hasOwnProperty.call(obj2, p)) {
        if (obj1[p] !== obj2[p]) {
          return false;
        }
      }
    }
    return true;
  },

  //만 나이 구하기 19920929 -> 29
  ageCalculator(birth) {
    //19920929 -> 1992,09,29 로 변경
    // const stringBirth = String(birth).replace(/(\d{4})(\d{2})(\d{2})/g, '$1,$2,$3');

    const birthDay = String(birth).replace(/(\d{4})(\d{2})(\d{2})/g, '$1');

    const today = new Date();
    // const birthDate = new Date(birthDay); // 2000년 8월 10일
    // const birthDate = new Date(stringBirth); // 2000년 8월 10일

    let age = today.getFullYear() - Number(birthDay);
    // let age = today.getFullYear() - birthDay.getFullYear();
    // const month = today.getMonth() - birthDate.getMonth();
    // utils.printLog('ageCalculator month', month);
    // if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    // 	utils.printLog('ageCalculator!!!!!!!!!!!!!!!!!!');
    // 	age--;
    // }

    utils.printLog('ageCalculator return age', age);
    return age;
  },

  splitPhoneNo(phoneNo) {
    const result = [];

    result.push(phoneNo.substr(0, 3));

    if (phoneNo.length == 11) {
      result.push(phoneNo.substr(3, 4));
      result.push(phoneNo.substr(7));
    } else {
      result.push(phoneNo.substr(6));
    }

    return result;
  },
  /**
   * freemode에서 스와이퍼 중간으로 이동하는 로직
   * @param _swiper swiper 객체
   * @param idx 스와이퍼 이동할 위치
   * @param delay 애니메이션 동작 시간
   */
  setCenteredSwiper(_swiper, idx, delay) {
    // jhkim: vue3: swiper wrapper 참조 방식 변경
    const wrapperEl = _swiper.el;

    let translateX = 0;

    if (_swiper.slidesGrid[idx] < _swiper.width / 2) {
      //좌측 끝
      translateX = 0;
    } else if (wrapperEl.scrollWidth - _swiper.slidesGrid[idx] < _swiper.width / 2) {
      //우측 끝
      // translateX = (this.swiper.width/2) - wrapperEl.scrollWidth
      translateX = (_swiper.virtualSize - _swiper.size) * -1;
    } else {
      //나머지
      translateX = (wrapperEl.clientWidth - _swiper.slidesSizesGrid[idx]) / 2 - _swiper.slidesGrid[idx];
    }

    _swiper.setTransition(delay);
    _swiper.setTranslate(translateX, 0);
  },
  /* 공유하기 웹버전 숏링크 생성 */
  async createShortLinkForWeb(parameters, imageLink, title) {
    let packageName;
    let baseUrl;

    switch (config.deployMode) {
      case 'DEV': //개발
        baseUrl = urlConfig.BASE_URL;
        packageName = 'com.tvpoint.dev';
        break;
      case 'STG': //스테이징
        baseUrl = urlConfig.BASE_URL;
        packageName = 'com.tvpoint.staging';
        break;
      case 'PRD': //상용
        baseUrl = urlConfig.BASE_URL;
        packageName = 'com.tvpoint';
        break;
    }

    let redirectUrl = `${baseUrl}/${config.context}/index.html#/${parameters}`;
    // this.printLog('☆ shore redirectUrl : :', redirectUrl);

    const params = {
      dynamicLinkInfo: {
        domainUriPrefix: 'https://fitcollabo.page.link', //동적링크 프리픽스 설정(파이어베이스 페이지에서 설정한 값 고정)
        // domainUriPrefix: 'https://tvhub.page.link', //동적링크 프리픽스 설정(파이어베이스 페이지에서 설정한 값 고정)
        link: redirectUrl, //사용자에게 실제로 띄워줄 링크
        androidInfo: {
          //안드로이드 파라미터 설정
          androidPackageName: packageName, //패키지명 셋팅(패키지명 별로 파이어베이스 앱 설정 추가되어있어야 마켓 리다이렉션 가능)
          androidFallbackLink: redirectUrl, //마켓이동이 아닌 경우 모바일 웹으로 보낼 시
          androidMinPackageVersionCode: '10', //앱 호출할 최소 앱 버전(기능이 추가되어 해당 메소드가 변경되어야 할경우 업데이트할 버전으로 올려줄것)
        },
        iosInfo: {
          //ios 앱
          iosBundleId: 'com.fitcollabo.fit', // 링크를 여는데 사용될 App의 Bundle ID
          iosIpadBundleId: 'com.fitcollabo.fit', // 링크를 여는데 사용될 App의 Bundle ID
          iosFallbackLink: redirectUrl, // 앱이 설치되지 않은 경우 iphone/ipad에서 열리는 링크
          iosAppStoreId: '6456410168', // App 미설치 시 App Store로 연결하기 위한 App Store ID
          // ISSUE : iosAppStoreId. App 미설치 시 App Store로 이동되지 않음. 심사 통과되지 않아 이동하지 않는것으로 생각됨
          // "iosCustomScheme": string, // 앱의 번들ID가 아닌것으로 정의된 경우 사용자 지정 url 체계 (없어도 문제없이 동작)
        },
        socialMetaTagInfo: {
          //소셜 게시물에서 동적링크 공유시 사용될 제목
          socialTitle: title, //게시물 타이틀
          socialImageLink: imageLink, //게시물 이미지
        },
      },
    };

    const data = await $store.dispatch('createShortLinkForTvpoint', params);
    $store.commit('lastShareUrl', data.shortLink);
    this.printLog('commons.js createShortLinkForWeb data', data);
    this.printLog('commons.js createShortLinkForWeb data.error', data.error);
    return data;
  },

  //공유하기
  doShare(e, title, messageLink) {
    const shareLink = !this.isNull(messageLink) ? messageLink : $store.state.tvpoint.lastShareUrl;
    this.printLog('commons.js doShare shareLink', shareLink);

    if ($store.state.common.deviceType === 1) {
      window.Android.doShare(shareLink, title);
      bus.$emit('end:loading');
    } else if ($store.state.common.deviceType === 3) {
      let params = {
        shareLink: shareLink,
        title: title,
      };
      $bridge.shared().call('doShare', params);
      bus.$emit('end:loading');
    } else {
      // TODO: iOS 동작 확인
      let el = document.createElement('textarea');
      let copyText = shareLink;
      el.value = copyText;
      el.style.opacity = '0';
      el.contentEditable = true;
      el.readOnly = true;

      //safari 스크롤 이벤트 발생으로 인해 document.body -> e.target 변경
      e.target.appendChild(el);

      el.select();
      el.setSelectionRange(0, 9999); // 셀렉트 범위 설정

      document.execCommand('copy');
      e.target.removeChild(el);

      $store.commit('setCommonToastPopup', { content: 'URL이 클립보드로 복사되었습니다.' });
    }
  },
  async subscribeFCM() {
    let data;
    if ($store.state.tvpoint.isLogin) {
      data = await $store.dispatch('fetchSubscribeList');
    }
    // const res = await $store.dispatch('fcmSubscribeList');

    this.printLog('☆ fcm subscribe data : ', JSON.stringify(data));
    if (!this.isNull(data)) {
      //0110000 는 비회원에게 내겨 오는 푸쉬이다 그래서 fcm에서 해지 시켜 버리기
      const params = {
        topic: '01100000',
        token: $store.state.common.token,
      };
      await $store.dispatch('fcmUnSubscribe', params);

      for (let topic of data.topics) {
        const payload = {
          topic: `${topic}`,
          token: $store.state.common.token,
        };

        this.printLog('===================================================');
        this.printLog('☆ fcm subscribe payload : ', JSON.stringify(payload));
        await $store.dispatch('fcmSubscribe', payload);
      }
    } else {
      //첫 진입시 푸쉬 동의 할 경우 비회원 혜택 보내기 위해 fcm 등록
      const params = {
        topic: '01100000',
        token: $store.state.common.token,
      };
      await this.$store.dispatch('fcmSubscribe', params);
    }
  },

  //상품상세 router시 통신
  // 추천상품 클릭시 상품상세>상품상세 로 이동시 router을 타지 않아 함수를 따로 뺌 240313 espark
  async productDetailEnter(to, from) {
    let sourceId = '';
    let componentId = '';
    let pushType = '';
    let mallCode = '';
    let componentType = '';

    if (to.fullPath.indexOf('sourceId') > -1) {
      sourceId = to.fullPath.split('sourceId=')[1].split('&')[0];
      $store.commit('setSourceId', sourceId);
    } else if (from.fullPath.indexOf('sourceId') > -1) {
      sourceId = from.fullPath.split('sourceId=')[1].split('&')[0];
      $store.commit('setSourceId', sourceId);
    }

    if (to.fullPath.indexOf('componentId') > -1) {
      componentId = to.fullPath.split('componentId=')[1].split('&')[0];
      $store.commit('setBannerId', componentId);
    } else if (from.fullPath.indexOf('componentId') > -1) {
      componentId = from.fullPath.split('componentId=')[1].split('&')[0];
      $store.commit('setBannerId', componentId);
    }

    if (to.fullPath.indexOf('pushType') > -1) {
      pushType = to.fullPath.split('pushType=')[1].split('&')[0];
      $store.commit('setFitcolType', pushType);
    } else if (from.fullPath.indexOf('pushType') > -1) {
      pushType = from.fullPath.split('pushType=')[1].split('&')[0];
      $store.commit('setFitcolType', pushType);
    }

    if (to.fullPath.indexOf('componentType') > -1) {
      componentType = to.fullPath.split('componentType=')[1].split('&')[0];
      $store.commit('setComponentType', componentType);
    } else if (from.fullPath.indexOf('componentType') > -1) {
      componentType = from.fullPath.split('componentType=')[1].split('&')[0];
      $store.commit('setComponentType', componentType);
    }

    if (!this.isNull($store.state.common.fitcolType)) {
      if ($store.state.common.fitcolType === 'A') {
        mallCode = 'PUSH';
      } else if ($store.state.common.fitcolType === 'L') {
        mallCode = 'LMS';
      } else if ($store.state.common.fitcolType === 'EVENT') {
        mallCode = 'EVENT';
      } else {
        mallCode = '/fit/pages/fitcall';
      }
      $store.commit('setMallCode', mallCode);
    }

    this.printLog('productDetailEnter sourceId : ', $store.state.common.sourceId);
    this.printLog('productDetailEnter bannerId : ', $store.state.common.bannerId);
    this.printLog('productDetailEnter mallCode : ', $store.state.bbarshop.mallCode);
    this.printLog('productDetailEnter salesPlatform : ', $store.state.common.salesPlatform);
    this.printLog('productDetailEnter shop_type : ', to.query.shop_type);
    this.printLog('productDetailEnter uri : ', to.query.uri);

    let fitcolCode = '';
    if (!this.isNull($store.state.common.sourceId)) {
      this.printLog('!$commons.isNull($store.state.common.sourceId)');
      fitcolCode = $store.state.common.sourceId;
    } else if (to.query.uri === '/fit/pages/fitcall') {
      fitcolCode = 'FIT00FIT01';
    }

    const id = to.query.id;
    const params = `${id}/?fitcol_code=${fitcolCode}`;

    $store.commit('productData', []);

    await $store
      .dispatch('bsProductDetail', params)
      .then(data => {
        if (data) {
          $store.commit('productData', data);

          if ($store.state.tvpoint.isLogin) {
            // fit서버에 productData log 기록 mjkim
            const logMessage = 'productData : [' + JSON.stringify(data) + '] index.vue';
            const saveLogParams = {
              sessionId: $store.getters.getSession,
              deviceId: $store.getters.getUserCel,
              tvTermId: Object.keys($store.state.tvpoint.tvInfo).length === 0 ? '' : $store.state.tvpoint.tvInfo.tvTermId,
              logMessage,
            };
            $store.dispatch('saveLog', saveLogParams);
          }

          const recommendParams = `?per_page=20&sort_by=random&category1=${data.category1}&exclude_id=${data.id}`;
          $store.dispatch('bsProductDetail', recommendParams).then(recommendData => {
            $store.commit('setRecommendProductRollingList', [recommendData]);
            $store.state.bbarshop.productRollingCompo.category1 = data.category1;
            $store.state.bbarshop.productRollingCompo.title = '함께 보면 좋은 상품';
            $store.state.bbarshop.productRollingCompo.link = { type: 2 };
            $store.state.bbarshop.productRollingCompo.subtitle = '';
          });

          if (data.company_id == 52) {
            const params = {
              appcardSubUrl: '/FIT-ORDER-RP-V1/order/getAvailableDates.mv',
            };
            $store.dispatch('fitReqRelay', params).then(data => {
              if (data.resultCode === '0000') {
                // 25년 설 연휴 배송 불가 요청으로 클라이언트 하드코딩, mjkim
                const date = { date: 25, month: 0, year: 2025 }; // 2025-01-25
                // const date2 = { date: 19, month: 8, year: 2024 }; // 2024-09-19
                data.resultData.dates.push(date);
                // data.resultData.dates.push(date2);
                $store.commit('setUnAvailableDates', data.resultData.dates);
              } else {
                this.printLog('getAvailableDates error');
              }
            });
          }

          //배너가져오기
          //?page=1&per_page=10&sort_by=order&sort_order=desc&product_id=8&company_id=52&categories=식품,생활잡화류,꽃
          let params2 = `?company_id=${data.company_id}&product_id=${data.id}&categories=${data.category1},${data.category2},${data.category3}&logon=${$store.state.tvpoint.isLogin}`;
          $store.dispatch('productDetailBanner', params2).then(data => {
            $store.commit('setRollingBannerList', data.product_banner_components);
          });

          // 상품상세 최상단으로 이동
          window.scrollTo(0, 0);
        } else {
          $store.commit('alertPopup', { popupType: 'DiscontinuedProduct' });
        }
      })
      .catch(err => {
        return false;
      });

    // eslint-disable-next-line no-unreachable
    if ($store.state.tvpoint.isLogin) {
      $store
        .dispatch('getUserUsablePoint', {
          jsessionid: $store.state.tvpoint.jsessionId,
          termType: $store.state.tvpoint.termType,
          deviceId: $store.state.tvpoint.userCel,
        })
        .then(data => {
          $utils.printLog(data);
          if (parseInt(data.resultCode) === 0) {
            $utils.printLog('getUserUsablePoint success');
            $store.state.tvpoint.pointBalance = data.availableBalance * 1;
          } else {
            $utils.printLog('getUserUsablePoint fail');
          }
        });
    }

    // eslint-disable-next-line no-unreachable
    if (this.isNull($store.state.fit.pageDepth2[0])) {
      await $store.dispatch('fitPageList');

      //fit 메뉴 탭 이름 변경
      const fitMenus = FTInitSetUp.makeMenus(Service.FIT);
      $store.commit('setFitMenus', fitMenus);

      //빠숍 메뉴 탭 이름 변경
      const bbarshopMenus = FTInitSetUp.makeMenus(Service.BSHOP);
      $store.commit('setBbarshopMenus', bbarshopMenus);
    }

    if (!this.isNull($store.state.fit.pageDepth2[0]) && $store.state.fit.pageDepth2.find(data => data.uri === '/bar/pages/product')) {
      // 핏콜 카드사 페이지에서 진입시 informEvent 가 안타도록 !$commons.isNull($store.state.fit.pageDepth2[0])추가 230601 espark
      const pageData = $store.state.fit.pageDepth2.find(data => data.uri === '/bar/pages/product');

      this.printLog('상품상세 pageData : ', pageData);
      this.printLog('상품상세 pageData uri : ', pageData.uri);

      this.printLog('상품상세 pageInfo 111 : ', JSON.stringify($store.state.fit.pageInform.pageInfo));
      this.printLog('상품상세 prevPageInfo 111 : ', JSON.stringify($store.state.fit.pageInform.prevPageInfo));

      if (
        !this.isNull($store.state.fit.pageInform.pageInfo) &&
        pageData.uri === $store.state.fit.pageInform.pageInfo.url &&
        pageData.id === $store.state.fit.pageInform.pageInfo.pageAttribute.product_id
      ) {
        this.printLog('상품상세 pageData.uri === $store.state.fit.pageInform.pageInfo.url');
      } else {
        $store.state.fit.pageInform.prevPageInfo = !this.isNull($store.state.fit.pageInform.pageInfo)
          ? $store.state.fit.pageInform.pageInfo
          : null;
        $store.state.fit.pageInform.pageInfo = {};

        this.printLog('상품상세 prevPageInfo : ', $store.state.fit.pageInform.prevPageInfo);
        this.printLog('상품상세 pushType : ', pushType);

        if (!this.isNull(pushType)) {
          if (pushType === 'EVENT') {
            this.printLog('pushType === : ', pushType);
            $store.state.fit.clickLink = false;
          } else if (from.fullPath !== '/') {
            $store.state.fit.clickLink = false;
          } else {
            this.printLog('pushType != : ', pushType);
            $store.state.fit.clickLink = true;
          }
        }
        $store.state.fit.pageInform.pageInfo.page_id = pageData.id;
        $store.state.fit.pageInform.pageInfo.title = pageData.title;
        $store.state.fit.pageInform.pageInfo.url = pageData.uri;
        $store.state.fit.pageInform.pageInfo.depth = pageData.depth;
        $store.state.fit.pageInform.pageInfo.pageAttribute = {
          click_link: $store.state.fit.clickLink,
          product_id: Number(to.query.id),
        };

        // if ($store.state.common.clickBack) {
        //   $store.state.fit.pageInform.pageInfo.click_back = true;
        //   $store.commit('setClickBack', false);
        // } else {
        //   $store.state.fit.pageInform.pageInfo.click_back = false;
        // }

        if (!this.isNull($store.state.common.alarmId)) {
          // APP PUSH 진입
          $store.commit('setSalesPlatform', 'BBARS00001');
          $store.commit('setMallCode', 'PUSH');
          $store.commit('setBannerId', $store.state.common.alarmId);
        } else if (Number(to.query.shop_type) === 2) {
          this.printLog('fitcollabo to.query.shop_type : ', Number(to.query.shop_type));
          // 핏콜라보 메뉴 진입
          this.printLog('pageInform uri : ', $store.state.fit.pageInform.pageInfo.url);
          $store.state.fit.pageInform.pageInfo.pageAttribute.type = 1;
          if ($store.state.bbarshop.mallCode === '/fit/pages/fitcall') {
            $store.commit('setSalesPlatform', 'FIT00FIT01');
            $store.state.fit.pageInform.pageInfo.pageAttribute.shop_type = 2;
          } else {
            $store.commit('setSalesPlatform', 'BBARS00001');
            $store.state.fit.pageInform.pageInfo.pageAttribute.shop_type = 1;
          }
        } else if (!this.isNull($store.state.common.sourceId)) {
          $store.commit('setSalesPlatform', $store.state.common.sourceId);
        } else {
          this.printLog('bbarshop to.query.shop_type : ', Number(to.query.shop_type));
          // 빠숍 메뉴 진입
          $store.state.fit.pageInform.pageInfo.pageAttribute.type = 1;
          if ($store.state.bbarshop.mallCode === '/fit/pages/fitcall') {
            $store.commit('setSalesPlatform', 'FIT00FIT01');
            $store.state.fit.pageInform.pageInfo.pageAttribute.shop_type = 2;
          } else {
            $store.commit('setSalesPlatform', 'BBARS00001');
            $store.state.fit.pageInform.pageInfo.pageAttribute.shop_type = 1;
          }
        }

        if (to.query.type !== 'relatedProduct' && $store.state.common.clickBack) {
          this.printLog('상품상세 relatedProduct ');
          $store.commit('setMallCode', $store.state.bbarshop.productInform.mallCode);
          $store.commit('setComponentType', $store.state.bbarshop.productInform.componentType);
          $store.commit('setBannerId', $store.state.bbarshop.productInform.bannerId);
        }

        if ($store.state.common.clickBack) {
          $store.state.fit.pageInform.pageInfo.click_back = true;
          $store.commit('setClickBack', false);
        } else {
          $store.state.fit.pageInform.pageInfo.click_back = false;
        }

        await $store.dispatch('informEvent', $store.state.fit.pageInform);
      }
      this.printLog('상품상세 pageInfo 222 : ', JSON.stringify($store.state.fit.pageInform.pageInfo));
      this.printLog('상품상세 prevPageInfo 222 : ', JSON.stringify($store.state.fit.pageInform.prevPageInfo));
    } else {
      // 핏콜 제휴사 페이지 진입
      $store.commit('setSalesPlatform', $store.state.common.sourceId);
      await $store.dispatch('informEvent', $store.state.fit.pageInform);
    }

    this.printLog('productDetailEnter_2 sourceId', $store.state.common.sourceId);
    this.printLog('productDetailEnter_2 bannerId', $store.state.common.bannerId);
    this.printLog('productDetailEnter_2 mallCode', $store.state.bbarshop.mallCode);
    this.printLog('productDetailEnter_2 salesPlatform', $store.state.common.salesPlatform);
    this.printLog('productDetailEnter_2 componentType', $store.state.common.componentType);

    if (to.query.type === 'relatedProduct' || to.query.type === 'productTag') {
      this.printLog('상품 상세 페이지에서 진입 to.query.type : ', to.query.type);
    } else {
      $store.commit('setProductInform', {
        bannerId: $store.state.common.bannerId,
        mallCode: $store.state.bbarshop.mallCode,
        salesPlatform: $store.state.common.salesPlatform,
        componentType: $store.state.common.componentType,
        productId: Number(to.query.id),
      });
    }
    this.printLog('productInform', $store.state.bbarshop.productInform);

    return true;
  },
};

export default commons;
