<template>
  <div>
    <div class="popup-wrap">
      <div class="popup-content">본 상품의 판매가 종료되었습니다.</div>
      <div class="popup-button-wrap flex-right-align-wrap">
        <button class="popup-button-yes" @click="confirm">확인</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DiscontinuedProduct',

  methods: {
    confirm() {
      this.$store.commit('alertPopup', {});
      if (window.history.length <= 1) {
        // 외부url, 공유하기, 문자 등 2depth로 바로 진입시 뒤로가기 눌렀을때 initPage로 이동되도록 espark
        this.$router.replace('/initPage');
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-wrap {
  padding: 40px;
}

.popup-button-yes {
  margin-left: 46px;
  font-size: 28px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #648cff;
  font-weight: bold;
  background-color: #ffffff;
}
.popup-button-wrap {
  margin-top: 57px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: flex-end;
}

.popup-content {
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #343534;
}
</style>
